import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"
import { Link } from "gatsby"
import ArrowRight from "../components/icons/arrowRight"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StoriesExpCtr = ({ data,location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content
  
  return (
    <Layout>
      <Nav urlParam={url_param}/>
      <div className="masthead relative">
        <div className="masthead hidden lg:block">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_desktop
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="masthead block lg:hidden">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_mobile
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        {/* <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
          <div className="container py-5 md:mt-36">
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
              {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
              <span className="inline md:block">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                  "" && <span className="inline">&nbsp;</span>}
              </span>
              {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                <span className="inline md:block md:mt-1">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                </span>
              )}
            </h1>
          </div>
        </div> */}
      </div>
      <section className="section sectionFirst">
        <div className="container py-5">
          <div className="about-description">
            
          <h2 className="text-xl md:text-3xl xl:text-4xl font-light uppercase mb-10">An Extravagant Retreat: the BMW Eurokars Experience Centre Customer Lounge</h2>
            
            <p className="mb-5 lg:pr-5">
            Nestled within the heart of luxury and automotive innovation, the BMW Eurokars Experience Centre unveils a customer lounge that transcends conventional expectations. Elevating the service experience to new heights, this haven of sophistication offers patrons a unique blend of culinary delights, premium beverages, and expert assistance, making each visit an indulgent affair.
            </p>
            <h3 className="mb-3"><strong>Culinary Delights</strong></h3>
            <p className="mb-5 lg:pr-5">
            As you step into the customer lounge at the BMW Eurokars Experience Centre, the aroma of freshly prepared dim sum and pastries welcomes you. The lounge offers an array of delectable treats crafted to tantalise your taste buds. From exquisite dim sum selections to artisan pastries, the lounge promises a gastronomic journey that mirrors the precision and excellence synonymous with the BMW brand.
            </p>
            <h3 className="mb-3"><strong>Imbibing Elegance: The Drink Bar</strong></h3>
            <p className="mb-5 lg:pr-5">
            Quench your thirst with a visit to the sophisticated drink bar, where a curated selection of beverages awaits. Indulge in a cup of expertly brewed coffee from our state-of-the-art coffee machine or explore the diverse range of teas, each sip embodying luxury and refinement. For those seeking a burst of freshness, enjoy a glass of freshly squeezed juice, perfectly complementing the lounge's commitment to a holistic and indulgent experience.
            </p>
            <h3 className="mb-3"><strong>Genius Assistance: BMW's In-House Experts</strong></h3>
            <p className="mb-5 lg:pr-5">
            Amidst the array of food and drinks stands our BMW Eurokars Auto Geniuses, where knowledgeable experts stand ready to answer queries and provide insights into the intricacies of your BMW. Whether you seek advice on cutting-edge technologies or have questions about the performance of your vehicle, the BMW Geniuses are dedicated to ensuring you leave with a deeper understanding and appreciation for your BMW.
            </p>
            <h3 className="mb-3"><strong>Test Drive Services: A Taste of Luxury in Motion</strong></h3>
            <p className="mb-5 lg:pr-5">
            While your BMW is undergoing service, the customer lounge offers an exclusive opportunity to explore the latest BMW models through the test drive services. Experience the sheer power and elegance of BMW's automotive engineering, allowing you to stay connected to the driving experience even while your car receives expert care.
            </p>
            <h3 className="mb-3"><strong>Unparalleled Comfort: Luxuriate in Style</strong></h3>
            <p className="mb-5 lg:pr-5">
            The customer lounge at the BMW Eurokars Experience Centre goes beyond the ordinary waiting area. Plush seating, elegant interiors, and a tranquil atmosphere create an environment where patrons can relax and rejuvenate. It is a space that embodies the essence of the BMW brand – a seamless fusion of luxury, innovation, and comfort.
            </p>
            <h3 className="mb-3"><strong>The Epitome of Service Excellence</strong></h3>
            <p className="mb-5 lg:pr-5">
            In essence, the BMW Eurokars Experience Centre customer lounge is a testament to the brand's commitment to providing a comprehensive and indulgent experience. From culinary delights to expert assistance and the opportunity to test drive the latest models, every aspect is carefully curated to ensure that you leave not only satisfied but enriched by the BMW lifestyle.  So, the next time your BMW needs undergoing servicing, immerse yourself in the opulence of the BMW Eurokars Experience Centre – a retreat where automotive excellence meets unparalleled luxury.
            </p>
            
            <p className="mb-5 lg:pr-5">
            Visit the BMW Eurokars Experience Centre at 11 Kung Chong Road, Singapore 159147, and experience this extravagant retreat yourself. 
            </p>

            <p className="text-neutral-400 hover:text-black flex float-right">
              <span className="w-5 -ml-1.5 ">
                <ArrowRight />
              </span>
              <span className="ml-1 font-bold text-black md:transition-colors md:duration-150 md:text-neutral-400 md:hover:text-black">
              <Link
                  to={"/stories/" + url_param}
                  data-gtm-category="BMW Press Release"
                  data-gtm-action="Clicked_Link"
                  data-gtm-label="View Stories">
                  <strong>Back to Stories</strong>
                </Link>
              </span>
            </p>
              
            <br /><br />
            
            <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
          </div>

        </div>
      </section>
      <Footer urlParam={url_param} />
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "stories-bmw-experience-centre" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default StoriesExpCtr

const seoDetails = {
  title: "Eurokars Auto | BMW Eurokars Auto Stories - BMW Experience Centre",
  description:
    "As an official dealer of BMW, Eurokars Auto offers the full range of BMW vehicles – from fully electric BMW i models to high-performance BMW M models.",
  keywords: "Eurokars, Eurokars Auto, BMW, BMW Eurokars Auto",
  image: "og-eka-about-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
